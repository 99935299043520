import React from "react";
import "./404.css";

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="error-page">
        <div className="content">
          <h2 className="header" data-text="404">
            404
          </h2>

          <h2 style={{ padding: "2% 0 0 0" }}>
            <u>IMPORTANT</u>
          </h2>
          <p style={{ padding: "0 20% 0 20%" }}>
            We're sorry to say, the item you are looking for cannot be found.
            Our apologies. For security reasons, links that visitors create in
            the demonstration sandbox at test.kamfupi.link are designed to
            disappear after ½ hour. Happily, you can easily use Kamfupi to share
            links and files forever! To set up your own account, write to
            kamfupi at{" "}
            <a href="mailto:contact@kamusi.org">contact@kamusi.org</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
