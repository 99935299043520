import { useState } from "react";

import {
  image1,
  image2,
  image3,
  image4,
  image5,
  image7,
  image8,
  image9,
  hieroglyphs,
} from "../../assets";

import "./demo.css";

const PageOne = ({ onNextPage }) => {
  return (
    <div className="main">
      <img
        src={"https://kamu.si/kamfupi-logo"}
        alt="logo"
        className="demo-logo"
      />
      <div className="demo-container">
        <h1 className="demo-h1">Welcome to Kamfupi by Kamusi Labs!</h1>
        <p>
          This is our Demonstration Sandbox. In this version, you’ll create
          items with names like{" "}
          <a href="https://kamusi.org" target="_blank" rel="noreferrer">
            http://demo.kamfupi.link/something
          </a>
          . In the full version, your items will have your custom name, like:{" "}
          <span> </span>
          <a href="https://kamusi.org" target="_blank" rel="noreferrer">
            http://myorganization.org/something
          </a>
          .
        </p>
        <p>
          Everything you do here will wash away after 30 minutes. Have fun
          playing!
        </p>
        <h2 className="big-text">
          Kamfupi does <span className="big">BIG</span> things in a{" "}
          <span className="small">small</span> package.
        </h2>
        <ul>
          <li>
            We built it for small organizations, or small departments in
            big organizations.
          </li>
          <li>It is also great for individuals who share a lot of stuff.</li>
          <li>
            We built it to share any file of any size. It’s much more powerful
            than anything else on the market, and a little more complicated.
          </li>
          <li>
            Just one small click of the &lt;&lt;🧙‍♀&gt;&gt; wizard invokes our
            superpowers!
          </li>
          <li>
            Once you love us, email kamfupi@kamusi.org to get your own account.
          </li>
        </ul>
      </div>

      <div className="button-container">
        <button className="demo-button" onClick={onNextPage}>
          Take a little tour
        </button>
        <span className="button-space"> </span>
        <a href="/dashboard" target="_blank" rel="noreferrer">
          <button className="demo-button">Jump right in</button>
        </a>
      </div>
    </div>
  );
};

const PageTwo = ({ onPrevPage, onNextPage }) => {
  return (
    <div className="main">
      <img
        src={"https://kamu.si/kamfupi-logo"}
        alt="logo"
        className="demo-logo"
      />

      <div className="demo-container">
        <h1 className="demo-h1">Kamfupi lets you share any item.</h1>
        <p>
          You can give your link ANY name. That name can include ANY character
          from ANY language, like Chinese or Arabic - even emojis and
          hieroglyphs.
        </p>
        <div className="p-links">
          <p>
            Like this:{" "}
            <a href="http://kamu.si/rose" target="_blank" rel="noreferrer">
              http://kamu.si/rose
            </a>
          </p>
          <p>
            Or this:{" "}
            <a href="http://kamu.si/玫瑰" target="_blank" rel="noreferrer">
              http://kamu.si/玫瑰
            </a>
          </p>
          <p>
            Or this:{" "}
            <a href="http://kamu.si/وردة" target="_blank" rel="noreferrer">
              http://kamu.si/وردة
            </a>
          </p>
          <p>
            Or this:{" "}
            <a href="http://kamu.si/🌹" target="_blank" rel="noreferrer">
              http://kamu.si/🌹
            </a>
          </p>
          <p>
            Or this:{" "}
            <a href="http://kamu.si/𓆸" target="_blank" rel="noreferrer">
              http://kamu.si/𓆸
            </a>
          </p>
        </div>

        <div className="p-links">
          <p>
            The name gets your custom brand:{" "}
            <a href="http://kamu.si/rose" target="_blank" rel="noreferrer">
              http://myorganization.org/rose
            </a>
          </p>
          <p>
            Or:{" "}
            <a href="http://kamu.si/rose" target="_blank" rel="noreferrer">
              http://myorganization.org/🌹
            </a>
          </p>
          <p>
            Or:{" "}
            <a href="http://kamu.si/rose" target="_blank" rel="noreferrer">
              http://myorganization.org/τριαντάφυλλο
            </a>
          </p>
          <p>
            Or you can fly your own custom name under the Kamfupi flag:
            http://kamfupi.link/my-name/rose
            http://kamfupi.link/my-organization/rose
          </p>
        </div>

        <p>
          The name is permanent. You can share it with anyone. You can share it
          in emails. You can share it in group chats, SMS messages, or DMs.
        </p>
        <p>
          You can use it in footnotes and bibliographies. You can put it on a
          poster, or anywhere on social media. You can show it on-screen in a
          video. You can speak it on a podcast. You can share it with one
          person, or a million. You can share URLs, documents, images, videos,
          music, or ANYTHING else. (For security reasons, “anything else” must
          be zipped into a compressed file.) It's your world - share what you
          like, as you like it!
        </p>
      </div>
      <div className="button-container">
        <button className="demo-button" onClick={onPrevPage}>
          Previous Page
        </button>
        <span className="button-space"> </span>
        <button className="demo-button" onClick={onNextPage}>
          Next Page
        </button>
      </div>
    </div>
  );
};

const PageThree = ({ onPrevPage, onNextPage }) => {
  return (
    <div className="main">
      <img
        src={"https://kamu.si/kamfupi-logo"}
        alt="logo"
        className="demo-logo"
      />
      <div className="demo-container">
        <h1 className="demo-h1">Sandbox Suggestions</h1>
        <p>
          You can try sharing any item on your computer, or any item on the
          Internet. You can give an item as many names as you like, in any
          language with UNICODE characters.
        </p>
        <ul>
          <li>
            On Google Maps, find your local library. Copy the URL and name it
            “library” in Kamfupi.
          </li>
          <li>
            In a Google Doc, open the sharing box, click “Copy Link”, and use
            Kamfupi to turn that long ugly URL into{" "}
            <a href="http://kamu.si/magic" target="_blank" rel="noreferrer">
              http://kamu.si/magic
            </a>
            .
          </li>
          <li>Try adding a document from your computer.</li>
          <li>
            Try adding an image or video. Bonus for Techies: You can grab the
            “Source Path”to embed in your code!
          </li>
          <li>
            For fun, you can make a shortcode&nbsp;using hieroglyphics from the
            ancient language known as Kushite or Meriotic, spoken 2000 years ago
            in present-day Sudan. As you play in the sandbox, just copy and
            paste the hieroglyphs you can see in the image below, from this
            Wikipedia link:{" "}
            <a href="http://kamu.si/ancient-emojis">
              http://kamu.si/ancient-emojis
            </a>
            ! More practically, try making shortcodes with ANY ALPHABET YOU
            LIKE.{" "}
            <a href="https://en.wikipedia.org/wiki/Devanagari">Devangari</a>,{" "}
            <a href="https://en.wikipedia.org/wiki/Mongolian_script">
              Mongolian
            </a>
            ,{" "}
            <a href="https://en.wikipedia.org/wiki/Cuneiform_(Unicode_block)">
              Cuneiform
            </a>{" "}
            - you name it, we probably have it!
          </li>
        </ul>
        <div>
          <img className="demo-img" src={hieroglyphs} alt="" />
        </div>
      </div>
      <div className="button-container">
        <button className="demo-button" onClick={onPrevPage}>
          Previous Page
        </button>
        <span className="button-space"> </span>
        <button className="demo-button" onClick={onNextPage}>
          Next Page
        </button>
      </div>
    </div>
  );
};

const PageFour = ({ onPrevPage, onNextPage }) => {
  return (
    <div className="main">
      <img
        src={"https://kamu.si/kamfupi-logo"}
        alt="logo"
        className="demo-logo"
      />
      <div className="demo-container">
        <h1 className="demo-h1">To add a Document from your computer:</h1>
        <ol>
          <li>
            Click on “Browse document” to access your files. You may have to
            click twice. Don’t ask why.
          </li>
          <li>
            Type in your name or names, using the &lt;&lt;comma&gt;&gt; key to
            validate as you go.
          </li>
          <li>
            If you want the item to be removed later, change “Keep Forever” to
            “Keep for 7 days”. (Items in the sandbox only keep for ½ hour.)
          </li>
          <li>
            Press &lt;&lt;🧙‍♀&gt;&gt; to transform your document into a short,
            shareable link.
          </li>
          <li>
            Once you see how this will change the way you share everything,
            email kamfupi@kamusi.org to get your own account.
          </li>
        </ol>
        <div>
          <img className="demo-img" src={image4} alt="" />
        </div>
        <p>
          The process is identical for other item types, like images, audio, or
          videos. Those items will open in a special player on many operating
          systems, so you can watch your videos or listen to your sounds.
        </p>
      </div>
      <div className="button-container">
        <button className="demo-button" onClick={onPrevPage}>
          Previous Page
        </button>
        <span className="button-space"> </span>
        <button className="demo-button" onClick={onNextPage}>
          Next Page
        </button>
      </div>
    </div>
  );
};

const PageFive = ({ onPrevPage, onNextPage }) => {
  return (
    <div className="main">
      <img
        src={"https://kamu.si/kamfupi-logo"}
        alt="logo"
        className="demo-logo"
      />
      <div className="demo-container">
        <h1 className="demo-h1">Here’s another secret:</h1>
        <p>You can click the ⊕ button to quickly add a new item.</p>
        <div>
          <img className="demo-img" src={image5} alt="" />
        </div>
        <p>And another secret:</p>
        <p>
          Email kamfupi@kamusi.org to set up a custom account for yourself or
          your favorite organization. Creating your account is a consultative
          process to make sure we meet your exact needs - you get boutique
          treatment for our boutique software!
        </p>
      </div>
      <div className="button-container">
        <button className="demo-button" onClick={onPrevPage}>
          Previous Page
        </button>
        <span className="button-space"> </span>
        <button className="demo-button" onClick={onNextPage}>
          Next Page
        </button>
      </div>
    </div>
  );
};

const PageSix = ({ onPrevPage, onNextPage }) => {
  return (
    <div className="main">
      <img
        src={"https://kamu.si/kamfupi-logo"}
        alt="logo"
        className="demo-logo"
      />
      <div className="demo-container">
        <h1 className="demo-h1">Did we make Sharing too easy?</h1>
        <p>
          When you create an item to Share, we copy its name right to your
          clipboard! You can share your new link with anyone, anywhere,
          immediately.
        </p>

        <div>
          <img className="demo-img" src={image9} alt="" />
        </div>

        <p>
          Anytime later on, tomorrow or years from now, you can easily find your
          creations and share them again in seconds. Whenever you need it, click
          the &lt;&lt;Share&gt;&gt; button to reshare any item!
        </p>
        <div>
          <img className="demo-img" src={image3} alt="" />
        </div>
        <p>
          &lt;&lt;Visit&gt;&gt; opens the item in a new browser tab on your own
          device.
        </p>
        <div>
          <img className="demo-img" src={image1} alt="" />
        </div>
      </div>
      <div className="button-container">
        <button className="demo-button" onClick={onPrevPage}>
          Previous Page
        </button>
        <span className="button-space"> </span>
        <button className="demo-button" onClick={onNextPage}>
          Next Page
        </button>
      </div>
    </div>
  );
};

const PageSeven = ({ onPrevPage, onNextPage }) => {
  return (
    <div className="main">
      <img
        src={"https://kamu.si/kamfupi-logo"}
        alt="logo"
        className="demo-logo"
      />
      <div className="demo-container">
        <h1 className="demo-h1">Did we make Managing too easy?</h1>
        <p>
          It's ultra-easy to MANAGE all your shares - find them again FAST,
          visit them yourself, add extra names, or CHANGE a link or document you
          are sharing without changing the name you use to share it.
        </p>
        <div>
          <img className="demo-img" src={image8} alt="" />
        </div>
      </div>
      <div className="button-container">
        <button className="demo-button" onClick={onPrevPage}>
          Previous Page
        </button>
        <span className="button-space"> </span>
        <button className="demo-button" onClick={onNextPage}>
          Next Page
        </button>
      </div>
    </div>
  );
};

const PageEight = ({ onPrevPage }) => {
  return (
    <div className="main">
      <img
        src={"https://kamu.si/kamfupi-logo"}
        alt="logo"
        className="demo-logo"
      />
      <div className="demo-container">
        <h1 className="demo-h1">
          You can also modify an item. You can do this in two ways:
        </h1>
        <div>
          <img className="demo-img" src={image2} alt="" />
        </div>
        <ol>
          <li>
            You can change the long URL or replace the document. For example,
            maybe you have a new version of the PDF for an event announcement.
            All you have to do is change the document behind the scenes. The
            name stays the same, and you don’t have to alert anyone (unless you
            want to)!
          </li>
          <li>
            You can change the short name, or add an additional one. Maybe you
            have /rose, and you also want /🌹for the same item?
          </li>
        </ol>
        <div>
          <img className="demo-img" src={image7} alt="" />
        </div>
        <ul>
          <li>
            Don’t clear both the old item and the old name. We have a delete
            feature 🗑️ for that.
          </li>
          <li>
            Don’t change both the old item and the old name. That would be silly
            - add a new item instead.
          </li>
          <li>
            Write to kamfupi@kamusi.org to purchase an account for yourself or
            your favorite organization.
          </li>
        </ul>
      </div>

      <div className="button-container">
        <button className="demo-button" onClick={onPrevPage}>
          Previous Page
        </button>
        <span className="button-space"> </span>
        <a href="/dashboard" target="_blank" rel="noreferrer">
          <button className="demo-button">Let me try, already!</button>
        </a>
      </div>
    </div>
  );
};

const Demo = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <div className="main-container">
      {currentPage === 1 && <PageOne onNextPage={handleNextPage} />}
      {currentPage === 2 && (
        <PageTwo onPrevPage={handlePrevPage} onNextPage={handleNextPage} />
      )}
      {currentPage === 3 && (
        <PageThree onPrevPage={handlePrevPage} onNextPage={handleNextPage} />
      )}
      {currentPage === 4 && (
        <PageFour onPrevPage={handlePrevPage} onNextPage={handleNextPage} />
      )}
      {currentPage === 5 && (
        <PageFive onPrevPage={handlePrevPage} onNextPage={handleNextPage} />
      )}
      {currentPage === 6 && (
        <PageSix onPrevPage={handlePrevPage} onNextPage={handleNextPage} />
      )}
      {currentPage === 7 && (
        <PageSeven onPrevPage={handlePrevPage} onNextPage={handleNextPage} />
      )}
      {currentPage === 8 && <PageEight onPrevPage={handlePrevPage} />}
    </div>
  );
};

export default Demo;
