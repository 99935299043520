import { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserName } from "../../features/components/componentSlice";
import Cookies from "js-cookie";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

import "./auth.css";
import Logo from "../../assets/kamfupi.png";

axios.defaults.withCredentials = true;

const URL = `${process.env.REACT_APP_BASE_URL}users`;

const initialState = {
  username: "",
};

const SignUp = () => {
  const [form, setForm] = useState(initialState);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    const { username } = form;

    await axios
      .post(
        `${URL}/signup`,
        {
          username,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        }
      )
      .then(
        (data) => {
          Cookies.set("jwt", data.data.token);
          toast.success("Account created successfully");
          window.location.href = "/login";
        },
        (error) => {
          toast.error(error.response.data.data.message);
        }
      );
  };
  return (
    <div className="container">
      <Toaster />
      <div className="wrapper">
        <div className="logo">
          <img src={"https://kamu.si/kamfupi-logo"} alt="logo" />
        </div>
        <div className="form-container">
          <div className="form-inner">
            <form onSubmit={handleSignup} className="signup">
              <p className="title-text">COME IN AND PLAY</p>
              <div className="field">
                <input
                  type="text"
                  placeholder="Username"
                  name="username"
                  required
                  onChange={handleChange}
                />
              </div>
              <p className="play">
                Welcome to the KamFupi demo site. Please provide a temporary
                username in the box above, then click the yellow bar.
              </p>
              <p className="play">
                For security reasons, any items you create in this sandbox{" "}
                <span className="emphasis">will be deleted</span> after 1/2
                hour, and your username{" "}
                <span className="emphasis">will be deleted</span> after 3 hours.
              </p>
              <div className="field btn">
                <div className="btn-layer"></div>
                <input
                  className="play"
                  type="submit"
                  value="ENTER THE TEST AREA"
                />
              </div>
              <div>
                <p>
                  Already have a test account? <a href="/login">Login</a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const Login = () => {
  const captchaRef = useRef(null);
  const [form, setForm] = useState(initialState);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const token = captchaRef.current.getValue();
    captchaRef.current.reset();

    const { username } = form;

    await axios
      .post(
        `${URL}/login`,
        {
          username,
          token,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        }
      )
      .then(
        (data) => {
          toast.success("Logged In Successfully");
          Cookies.set("jwt", data.data.token);
          let username = data.data.data.user.username;
          dispatch(setUserName(username));
          navigate("/demo");
        },
        (error) => {
          toast.error(error.response.data.data.message);
        }
      );
  };
  return (
    <div className="container">
      <Toaster />
      <div className="wrapper">
        <div className="logo">
          <img src={"https://kamu.si/kamfupi-logo"} alt="logo" />
        </div>

        <div className="form-container">
          <div className="form-inner">
            <form onSubmit={handleLogin}>
              <p className="title-text">
                CONFIRM YOUR USERNAME AND YOUR HUMANITY
              </p>
              <div className="field">
                <input
                  type="text"
                  placeholder="Username"
                  name="username"
                  required
                  onChange={handleChange}
                />
              </div>
              <div style={{ margin: "10px 0 " }}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                />
              </div>
              <div className="field btn">
                <div className="btn-layer"></div>
                <input type="submit" value="ENTER THE TEST AREA" />
              </div>
              <div>
                <p className="play">
                  <span className=" emphasis">
                    NOTE: Demo visitors can test with files up to 20MB. There
                    are no size restrictions for full users
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const Auth = () => {
  return <Login />;
};

export { SignUp };
export default Auth;
