import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth from "./components/auth/Auth";
import { SignUp } from "./components/auth/Auth";
import Dashboard from "./components/dashboard/Dashboard";
import Create from "./components/create/Create";
import NotFound from "./components/notFound/NotFound";
import Demo from "./components/demo/Demo";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignUp />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/create" element={<Create />} />
          <Route path="/notFound" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
