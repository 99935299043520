import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { changeComponent } from "../../features/components/componentSlice";
import axios from "axios";
import Cookies from "universal-cookie";
import diacritics from "diacritics";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { uniq } from "lodash";
import toast, { Toaster } from "react-hot-toast";
import Tag from "../../assets/kamfupi.png";
import { SpinnerCircularFixed } from "spinners-react";

import "./create.css";

axios.defaults.withCredentials = true;

const cookies = new Cookies();

const Create = ({ createInput, extension }) => {
  const dispatch = useDispatch();

  const [longUrl, setUrl] = useState([]);
  const [urlCodes, setCodes] = useState([]);
  const [input, setInput] = useState(createInput);
  const [file, setFile] = useState(null);
  const [nameFile, setNameFile] = useState(null);
  const [mimeType, setMimeType] = useState(null);
  const [acceptType, setAccept] = useState(extension);
  const [duration, setDuration] = useState("Forever");
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [userRole, setUserRole] = useState({});
  const [cancelToken, setCancelToken] = useState(null);

  const [chunkSize, setChunkSize] = useState(500 * 1024);

  const copyTextToClipboard = (text) => {
    // Create a temporary textarea element
    const textarea = document.createElement("textarea");
    textarea.value = text;

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the text inside the textarea
    textarea.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the textarea from the body
    document.body.removeChild(textarea);
  };

  const replaceHttpsWithHttp = (url) => url.replace(/^https:\/\//, "http://");

  const progressRef = useRef([]);

  const updatedProgress = useMemo(() => {
    return files.map((file, fileIndex) => {
      if (file.size < chunkSize) {
        setChunkSize(file.size / 2);
      }

      if (file.finalFilename) {
        return 100;
      }

      const uploading = fileIndex === currentFileIndex;
      const chunks = Math.ceil(file.size / chunkSize);

      if (uploading) {
        const currentProgress = Math.round((currentChunkIndex / chunks) * 100);
        progressRef.current[fileIndex] = currentProgress;
        return currentProgress;
      }

      return progressRef.current[fileIndex] || 0;
    });
  }, [files, chunkSize, currentFileIndex, currentChunkIndex]);

  useEffect(() => {
    setProgress(updatedProgress);
  }, [updatedProgress, setProgress]);

  const ProgressBar = () => {
    return (
      <div className="progress-bar">
        <div className="progress" style={{ width: progress + "%" }}></div>
      </div>
    );
  };

  const Upload = ({ uploadType }) => {
    return (
      <div className="upload-wrapper">
        <Toaster position="top-right" reverseOrder={false} />
        <header>Upload {uploadType}</header>
        <form
          action="#"
          encType="multipart/form-data"
          style={{ position: "relative" }}
        >
          <input
            className="file-input"
            type="file"
            id="file-input"
            hidden
            onChange={(e) => handleUpload(e)}
            accept={acceptType}
          />
          <label htmlFor="file-input" style={{ cursor: "pointer" }}>
            {uploadType === "document" && (
              <i className="bx bx-book-bookmark"></i>
            )}
            {uploadType === "image" && <i className="bx bx-image"></i>}
            {uploadType === "audio" && <i className="bx bx-music"></i>}
            {uploadType === "video" && <i className="bx bx-video"></i>}
            {uploadType === "compressed file" && (
              <i className="bx bxs-file-archive"></i>
            )}
          </label>
          <p>Browse {uploadType} to Upload</p>
          <select
            className="duration"
            style={{
              position: "absolute",
              left: "70%",
              top: "30%",
              outline: "none",
            }}
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          >
            <option value="Forever">Keep Forever</option>
            <option value="1 Week">Keep For 7 Days</option>
          </select>
        </form>
        <section className="progress-area">
          {file && (
            <li className="row">
              <i
                className="bx bx-x"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleCancelClick();
                  setFile(null);
                }}
              ></i>
              <div className="content">
                <div className="details">
                  <span className="name">{nameFile}</span>
                  <span className="size">
                    {progress + "%"} <span> of </span>
                    {file.size / 1000 < 1024
                      ? (file.size / 1000).toFixed(1) + " KB"
                      : (file.size / (1000 * 1000)).toFixed(1) + "MB"}
                  </span>
                </div>
                <ProgressBar />
              </div>
            </li>
          )}
        </section>
        <section className="uploaded-area"></section>
      </div>
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    const token = cookies.get("jwt");
    setUserRole(jwt_decode(token));
    if (!token) {
      navigate("/login", { replace: true });
    }
    createInput === "documents" &&
      setAccept(
        ".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .html, .pdf, .html5, .css"
      );
    createInput === "images" && setAccept("image/*");
    createInput === "audios" &&
      setAccept(".aac, .midi, .mp3, .ogg, .wav, .m4a");
    createInput === "videos" && setAccept("video/*, .mkv");
    createInput === "compressed" && setAccept(".zip,.rar,.7z,.gz");
  }, [createInput, navigate]);

  const addLongUrl = (e) => {
    setUrl(e.target.value);
  };

  const addCodes = (e) => {
    let codeArr = diacritics
      .remove(e.target.value)
      .replace(/\s+/g, " ")
      .split(",")
      .map((code) =>
        code
          .trim()
          .split(" ")
          .join("-")
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      );

    const finalCodes = uniq(urlCodes.concat(codeArr));

    let filteredArray = finalCodes.filter(function (element) {
      return element !== "";
    });

    finalCodes.forEach((code) => {
      console.log(code.length);
      if (code.length > 0 && code.length !== 0 && !urlCodes.includes(code)) {
        setCodes(filteredArray);
        e.target.value = null;
      }
    });
  };

  const removeTags = (indexToRemove) => {
    setCodes(urlCodes.filter((_, index) => index !== indexToRemove));
  };

  const removeAll = () => {
    setCodes([]);
  };

  const handleSubmit = async () => {
    const URL = `${process.env.REACT_APP_BASE_URL}u`;
    setLoading(true);

    if (!longUrl.length > 0) {
      setLoading(false);
      return toast.error(
        `Please input a long URL along with its associated short codes. Press 'Enter' to generate the corresponding short URL`
      );
    }

    if (!urlCodes.length > 0) {
      setLoading(false);
      return toast.error(
        `Name this long URL with at least one short code and hit "Enter" to preview. Then hit "Shorten" to submit all new codes.`
      );
    }

    await axios
      .post(
        URL,
        {
          longUrl,
          urlCodes,
        },
        {
          headers: { Authorization: `Bearer ${cookies.get("jwt")}` },
        }
      )
      .then(
        (data) => {
          dispatch(changeComponent("getUrls"));
          setLoading(false);
          toast.success(
            `Success! Your item is ready to share. The link to ${process.env.REACT_APP_BASE_URL}${urlCodes[0]} is in your clipboard.`
          );
        },
        (error) => {
          error.response
            ? toast.error(error.response.data?.data?.message)
            : toast.error(
                "Oops! Something went wrong on our end. We sincerely apologize for the inconvenience."
              );
          setLoading(false);
        }
      );

    copyTextToClipboard(
      replaceHttpsWithHttp(`${process.env.REACT_APP_BASE_URL}${urlCodes[0]}`)
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (e.target.tagName.toLowerCase() === "input") {
        if (input === "urls") {
          handleSubmit();
        } else {
          uploadFile();
        }
      }
    }
  };

  console.log(input);

  function handleUpload(e) {
    e.preventDefault();
    setFile(e.target.files[0]);

    let uploadedFile = e.target.files[0];

    if (uploadedFile) {
      let fileName = uploadedFile.name;

      if (fileName.length >= 12) {
        let splitName = fileName.split(".");
        fileName = splitName[0].substring(0, 13) + "... ." + splitName[1];
      }

      setMimeType(uploadedFile.type.split("/")[0]);
      setNameFile(fileName);
    }

    setFiles([...e.target.files]);
  }

  function readAndUploadCurrentChunk() {
    const reader = new FileReader();
    const fileUpload = files[currentFileIndex];
    if (!fileUpload) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = fileUpload.slice(from, to);
    reader.onload = (e) => uploadChunk(e);
    reader.readAsDataURL(blob);
  }

  // function removeHttp(url) {
  //   return url.replace(/^https?:\/\//, "");
  // }

  async function uploadChunk(readerEvent) {
    let uploadUrl = `${process.env.REACT_APP_BASE_URL}f/upload?`;
    setLoading(true);

    input === "compressed" &&
      (uploadUrl = `${process.env.REACT_APP_BASE_URL}c/upload?`);

    const cancelSource = axios.CancelToken.source();
    setCancelToken(cancelSource);

    const fileToUpload = files[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set("name", fileToUpload.name);
    params.set("size", fileToUpload.size);
    params.set("currentChunkIndex", currentChunkIndex);
    params.set("totalChunks", Math.ceil(fileToUpload.size / chunkSize));
    params.set("urlCodes", urlCodes);
    params.set("duration", duration);
    params.set("mimeType", mimeType);
    params.set("userId", userRole.id);

    const headers = {
      "Content-Type": "application/octet-stream",
      Authorization: `Bearer ${cookies.get("jwt")}`,
    };
    const url = uploadUrl + params.toString();

    urlCodes.length > 0 &&
      (await axios
        .post(url, data, {
          headers,
          cancelToken: cancelSource.token,
        })
        .then(
          (response) => {
            const fileToUpload = files[currentFileIndex];
            const fileSize = files[currentFileIndex].size;
            const chunks = Math.ceil(fileSize / chunkSize) - 1;
            const isLastChunk = currentChunkIndex === chunks;

            if (isLastChunk) {
              setLoading(false);
              input === "documents" &&
                dispatch(changeComponent("getDocuments"));
              input === "images" && dispatch(changeComponent("getImages"));
              input === "audios" && dispatch(changeComponent("getAudio"));
              input === "videos" && dispatch(changeComponent("getVideos"));
              input === "compressed" &&
                dispatch(changeComponent("getCompressed"));
              toast.success(
                `Success! Your item is ready to share. The link to ${process.env.REACT_APP_BASE_URL}${urlCodes[0]} is in your clipboard.`
              );
              console.log(urlCodes);
              setCodes([]);

              fileToUpload.finalFilename = response.data.finalFilename;
              setLastUploadedFileIndex(currentFileIndex);
              setCurrentChunkIndex(null);
            } else {
              setCurrentChunkIndex(currentChunkIndex + 1);
            }
          },
          (error) => {
            if (cancelToken) {
              toast.error("Upload Cancelled Successfully.");
            } else if (error.response) {
              toast.error(error.response.data?.data?.message);
            } else {
              toast.error(
                "Oops! Something went wrong on our end. We sincerely apologize for the inconvenience."
              );
            }
            setLoading(false);
          }
        ));
  }

  useEffect(() => {
    if (lastUploadedFileIndex !== null) {
      const isLastFile = lastUploadedFileIndex === files.length - 1;
      const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
      setCurrentFileIndex(nextFileIndex);
    }
  }, [lastUploadedFileIndex, files.length, currentFileIndex]);

  useEffect(() => {
    if (files.length > 0 && currentFileIndex === null) {
      setCurrentFileIndex(
        lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
      );
    }
  }, [files.length, currentFileIndex, lastUploadedFileIndex]);

  const uploadFile = async () => {
    if (!files.length > 0) {
      return toast.error(`Please attach a file to proceed with the upload.`);
    }
    if (loading) {
      // If upload is already in progress, ignore the button press
      return;
    }

    try {
      setLoading(true);

      if (!urlCodes.length > 0) {
        return toast.error(
          `Assign a short code to this file and press 'Wizard' or hit 'Enter' to generate the unique URL shortcode.`
        );
      }

      if (currentFileIndex !== null) {
        await readAndUploadCurrentChunk();
        setCurrentChunkIndex(0);
      }

      copyTextToClipboard(
        replaceHttpsWithHttp(`${process.env.REACT_APP_BASE_URL}${urlCodes[0]}`)
      );
    } finally {
      setLoading(false); // Reset the flag regardless of success or failure
    }
  };

  const handleCancelClick = () => {
    if (cancelToken) {
      setCurrentChunkIndex(0);
      setFiles([]);
      setFile(null);
      cancelToken.cancel("Upload cancelled by user.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currentChunkIndex !== 0) {
      readAndUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  return (
    <div className="create-container">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="create-wrapper">
        <div className="create-title">
          <img src={Tag} alt="tag" />
          <h2>Shorten and Share</h2>
        </div>
        <div className="create-content">
          <nav className="navbar">
            <Link
              to="shorten/long-url"
              onClick={() => {
                setInput("urls");
                setFile(null);
              }}
            >
              long url
            </Link>
            <Link
              to="shorten/document"
              onClick={() => {
                setInput("documents");
                setAccept(
                  ".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .html, .pdf, .html5, .css"
                );
                setFile(null);

                setCodes([]);
              }}
            >
              document
            </Link>
            <Link
              to="shorten/image"
              onClick={() => {
                setInput("images");
                setAccept("image/*");
                setFile(null);

                setCodes([]);
              }}
            >
              image
            </Link>
            <Link
              to="shorten/video"
              onClick={() => {
                setInput("videos");
                setAccept("video/*, .mkv");
                setFile(null);

                setCodes([]);
              }}
            >
              video
            </Link>
            <Link
              to="shorten/audio"
              onClick={() => {
                setInput("audios");
                setAccept(".aac, .midi, .mp3, .ogg, .wav, .m4a");
                setFile(null);

                setCodes([]);
              }}
            >
              audio
            </Link>
            <Link
              to="shorten/compressed"
              onClick={() => {
                setInput("compressed");
                setAccept(".zip");
                setFile(null);

                setCodes([]);
              }}
            >
              compressed
            </Link>
          </nav>
          <hr />
          {input === "urls" && (
            <ul>
              <input
                type="text"
                placeholder="long url"
                name="longUrl"
                value={longUrl}
                onChange={(e) => addLongUrl(e)}
                disabled={loading}
              />
            </ul>
          )}
          {input === "documents" && (
            <Upload uploadType={"document"} shortCodes={urlCodes} />
          )}
          {input === "images" && (
            <Upload uploadType={"image"} shortCodes={urlCodes} />
          )}
          {input === "audios" && (
            <Upload uploadType={"audio"} shortCodes={urlCodes} />
          )}
          {input === "videos" && (
            <Upload uploadType={"video"} shortCodes={urlCodes} />
          )}
          {input === "compressed" && (
            <Upload uploadType={"compressed file"} shortCodes={urlCodes} />
          )}
          {input !== "urls" ? (
            <p>
              Your upload will start after you (1) choose a file, (2) give it a
              name, and (3) click &lt;&lt;🧙‍♀&gt;&gt;
            </p>
          ) : (
            <p>
              Your short url will be ready after you (1) Add a long url, (2)
              give it a name, and (3) click &lt;&lt;🧙‍♀&gt;&gt;
            </p>
          )}
          <ul>
            {urlCodes.map((code, index) => (
              <li key={index}>
                {code}
                <i className="bx bx-x" onClick={() => removeTags(index)}></i>
              </li>
            ))}

            <input
              type="text"
              spellCheck="false"
              name="urlCodes"
              placeholder="type your short code (item name) here"
              onBlur={(e) => addCodes(e)}
              onKeyUp={(e) => (e.key === "," ? addCodes(e) : null)}
              onKeyDown={(e) => (e.key === "Enter" ? addCodes(e) : null)}
              onKeyPress={handleKeyPress}
              disabled={loading}
            />
          </ul>
        </div>
        <div className="create-details">
          <p>
            <span>{urlCodes.length}</span> short codes
          </p>
          <button onClick={removeAll} disabled={loading}>
            Clear Short Codes
          </button>
          {input === "urls" ? (
            <button onClick={() => handleSubmit()}>&lt;&lt;🧙‍♀&gt;&gt;</button>
          ) : (
            <button onClick={() => uploadFile()}>&lt;&lt;🧙‍♀&gt;&gt;</button>
          )}

          <SpinnerCircularFixed
            size={20}
            thickness={180}
            speed={100}
            color="rgba(214, 154, 51, 1)"
            secondaryColor="rgba(0, 0, 0, 1)"
            enabled={loading}
          />

          {loading && (
            <p>{input === "urls" ? "Shortening...." : "Uploading...."}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Create;
