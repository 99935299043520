import { useState, useEffect } from "react";
import Clipboard from "react-clipboard.js";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Cookies from "universal-cookie";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import "../share/share.css";

const URL = `${process.env.REACT_APP_BASE_URL}users/invite`;

axios.defaults.withCredentials = true;

const cookies = new Cookies();

function ClipboardCopy() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = cookies.get("jwt");
    if (!token) {
      navigate("/login", { replace: true });
    }
  }, []);

  const [isCopied, setIsCopied] = useState(false);
  const [inviteLink, setInviteLink] = useState("");

  let linkInvite;

  const copyText = inviteLink;

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="field">
        <i className="bx bx-link-alt icon"></i>
        {copyText ? (
          <input type="text" value={copyText} readOnly />
        ) : (
          <input type="text" value={null} readOnly />
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "2rem",
        }}
      >
        <button
          className="share-button"
          style={{ height: "35px", marginTop: "16px" }}
          onClick={async () => {
            linkInvite = `${
              process.env.REACT_APP_BASE_URL
            }users/invite/${uuidv4()}`;
            setInviteLink(linkInvite);
            await axios
              .post(
                `${URL}`,
                {
                  linkInvite,
                },
                {
                  headers: { Authorization: `Bearer ${cookies.get("jwt")}` },
                }
              )
              .then(
                (data) => {
                  toast.success("Invite link generated successfully");
                },
                (error) => {
                  toast.error(error.response.data.data.message);
                }
              );
          }}
        >
          Generate Invite
          <span> </span>
          <i className="bx bx-link icon" style={{ textAlign: "center" }}></i>
        </button>
        <Clipboard
          data-clipboard-text={copyText}
          className="field share-button"
          onClick={handleCopyClick}
          style={{ height: "35px", outline: "none", border: "none" }}
        >
          <span>{isCopied ? "Copied!" : "Copy Link"}</span>
        </Clipboard>
      </div>
    </>
  );
}

const Invite = () => {
  return (
    <div className="create-container" style={{ flexDirection: "column" }}>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="create-wrapper" style={{ marginBottom: "10px" }}>
        <ClipboardCopy />
      </div>
    </div>
  );
};
export default Invite;
