import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Usage Data",
    },
  },

  scales: {
    y: {
      ticks: {
        stepSize: 1,
      },
    },
  },
};

export function Stats({ data }) {
  return (
    <>
      <Bar
        options={options}
        data={data}
        style={{
          width: "100%",
          marginLeft: "10%",
        }}
      />
    </>
  );
}
